import Script from 'next/script'
import { ENV } from 'src/utils/env'
import { type PendoVisitorMeta, usePendo } from './usePendo'

declare global {
  interface Window {
    pendo: {
      initialize: (data: PendoVisitorMeta) => void
    }
  }
}

/**
 * Initializes Pendo script. `beforeInteractive` strategy is used to ensure that
 * Pendo is ready to be initialized properly during the `onPendoReady` callback.
 */
export function PendoScript() {
  const { onPendoReady, isDisabled } = usePendo()

  if (isDisabled) return null

  return (
    <Script id="pendo-script" strategy="beforeInteractive" onReady={onPendoReady}>
      {`(function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        })('${ENV.PENDO_API_KEY}');`}
    </Script>
  )
}
