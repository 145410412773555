import { useCallback, useMemo } from 'react'
import { useAuth } from 'src/auth'
import { useTenantId } from 'src/data/queries/config'
import { useCompany } from 'src/data/use-company'
import { useFeatureFlagSSR } from 'src/utils/config-cat/ssr'
import { ENV } from 'src/utils/env'

type PendoMetaValue = Record<string, string | number | boolean>
export type PendoVisitorMeta = {
  visitor?: PendoMetaValue
  account?: PendoMetaValue
}

/**
 * Custom hook for Pendo integration.
 * - Maps the current session and company metadata into Pendo's understanding of 'visitor's and 'account's.
 * @returns user and account information to be sent to Pendo.
 */
export function usePendo() {
  const company = useCompany()
  const { data: tenantId } = useTenantId()
  const { user, slug, session, authenticated, loading: isLoading } = useAuth()
  const isPendoIntegrationEnabled = useFeatureFlagSSR('isPendoIntegrationEnabled', false)

  const isDisabled = isLoading || !isPendoIntegrationEnabled || !authenticated || !ENV.PENDO_API_KEY

  const initializationData: PendoVisitorMeta | undefined = useMemo(() => {
    if (isDisabled) return

    const primaryEmail = user?.email_addresses?.data.find((email) => email.primary)?.email
    const fullName = [user?.first_name, user?.last_name].filter(Boolean).join(' ')

    return {
      visitor: {
        // Pendo's reserved attributes
        id: session?.user?.bushelId,
        email: primaryEmail,
        full_name: fullName,
        role: user?.user_type,
        // Our custom attributes
        bushelId: session?.user?.bushelId,
        userId: user?.id,
      },
      // Pendo reserves `id` attribute
      account: { slug, tenantId, id: tenantId, name: company?.company_name },
    } as PendoVisitorMeta
  }, [user, slug, session, tenantId, company, isDisabled])

  const onPendoReady = useCallback(() => {
    if (isDisabled || typeof window?.pendo === 'undefined' || !initializationData) return

    window.pendo.initialize(initializationData)
  }, [isDisabled, initializationData])

  return { onPendoReady, isDisabled }
}
